import { useState, useEffect } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import locationPin from '../../assets/images/location-pin.png';
import styles from './InfoSection.module.css';
import buildingGray from "../../assets/images/building-gray.png";

const InfoSection = (props) => {
	const [location, setLocation] = useState('');
	const [startEndText, setStartEndText] = useState('');
	const infoSectionClasses = classNames(styles.infoSection, {
		[styles.hasImages]: props.fundraiser?.pictures?.length > 0
	});

	const parseLocationText = () => {
		if(props.fundraiser.organization?.city && props.fundraiser.organization?.state){
			setLocation(props.fundraiser.organization.city + ', ' + props.fundraiser.organization.state);
		}
		else if(props.fundraiser.organization?.city){
			setLocation(props.fundraiser.organization.city);
		}
		else if(props.fundraiser.organization?.state){
			setLocation(props.fundraiser.organization.state);
		}
		else{
			setLocation('');
		}
	};

	const parseDaysDifferenceText = (daysDifference, isStartIn) => {
		if(daysDifference === 0){
			return 'today'
		}
		else if(daysDifference === 1){
			return (isStartIn ? 'in ' : '') + '1 day' + (!isStartIn ? ' ago' : '');
		}
		else{
			return (isStartIn ? 'in ' : '') + daysDifference + ' days' + (!isStartIn ? ' ago' : '');
		}
	};

	const parseStartEndText = () => {
		const currentDatetime = moment();
		const startAtDatetime = moment(props.fundraiser.start_at);
		const endAtDatetime = moment(props.fundraiser.end_at);
		if(currentDatetime < startAtDatetime){
			const daysDifference = parseDaysDifferenceText(startAtDatetime.diff(currentDatetime, 'days'), true);
			setStartEndText('Starts ' + daysDifference);
			props.setStatus('scheduled');
		}
		else if(currentDatetime >= startAtDatetime && currentDatetime < endAtDatetime){
			const daysDifference = parseDaysDifferenceText(currentDatetime.diff(startAtDatetime, 'days'), false);
			setStartEndText('Started ' + daysDifference);
			props.setStatus('in-progress');
		}
		else if(currentDatetime >= endAtDatetime){
			const daysDifference = parseDaysDifferenceText(currentDatetime.diff(endAtDatetime, 'days'), false);
			setStartEndText('Ended ' + daysDifference);
			props.setStatus('ended');
		}
		else{
			setStartEndText('');
		}
	};

	useEffect(() => {
		parseLocationText();
		parseStartEndText();
	}, [props.fundraiser]);

	return (
		<div className={infoSectionClasses}>
            <div className={styles.leftSide}>
                <div className={styles.organizer}>
                    {props.fundraiser.organizer?.profile_picture_url && (
                        <img className={styles.organizerPicture} src={process.env.REACT_APP_CLOUDFRONT_S3_DOMAIN + props.fundraiser.organizer.profile_picture_url} alt="Organizer"/>
                    )}
                    {!props.fundraiser.organizer?.profile_picture_url && (
                        <span className={styles.imagePlaceholder}>
                            {props.fundraiser.organizer?.first_name.slice(0, 1).toUpperCase() + ' ' + props.fundraiser.organizer?.last_name.slice(0, 1).toUpperCase()}
						</span>
                    )}
                    <span className={styles.organizerName}>
                        {props.fundraiser.organizer?.first_name} {props.fundraiser.organizer?.last_name}
                    </span>
                </div>
                {
                    props.fundraiser.organization_id !== 0 && (
                        <div className={styles.organization}>
                            <span className={styles.viaText}>
                                via
                            </span>
							<a className={styles.organizationLinkContainer} href={'/organization/' + props.fundraiser.organization_id} target="_blank">
								{props.fundraiser.organization?.picture_url && (
									<img className={styles.organizationPicture} src={process.env.REACT_APP_CLOUDFRONT_S3_DOMAIN + props.fundraiser.organization.picture_url}
										 alt="Organization"/>
								)}
								<span className={styles.organizationName}>
									{props.fundraiser.organization?.name}
								</span>
							</a>
                        </div>
                    )
                }
                {
                    location && (
                        <span className={styles.organizationLocation}>
							<img className={styles.locationPin} src={locationPin} alt="Location Pin"/>
							<span>
								{location}
							</span>
						</span>
                    )
                }
            </div>
            <div className={styles.startEndText}>
                {startEndText}
            </div>
        </div>
    )
};

export default InfoSection;