import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import signup from '../assets/images/signup.png';
import styles from './Signup.module.css';
import { signupApi } from '../api/auth.js';
import { stateOptions } from "../data/ProfileOptions";
import ButtonLink from '../components/ButtonLink.jsx';
import FormHeader from '../components/form/FormHeader.jsx';
import FormRadioButton from "../components/form/FormRadioButton";
import FormInput from '../components/form/FormInput.jsx';
import FormDropdown from "../components/form/FormDropdown";
import FormButton from '../components/form/FormButton.jsx';
import FormError from '../components/form/FormError.jsx';

const Signup = () => {
	const navigate = useNavigate();
    const [type, setType] = useState('individual');
	const [formData, setFormData] = useState({
		email: '',
		password: '',
		confirmPassword: '',
		firstName: '',
		lastName: '',
		organization: '',
        streetOne: '',
        city: '',
        state: 'CA',
        zip: '',
        ein: ''
	});
	const initialErrorObject = {
		email: false,
		password: false,
		confirmPassword: false,
		firstName: false,
		lastName: false,
		organization: false,
        streetOne: false,
        city: false,
        state: false,
        zip: false,
        ein: false,
		apiError: false,
	};
	const [errorObject, setErrorObject] = useState(initialErrorObject);
	const inputsMessagesErrorObject = {
		email: 'Missing email',
		password: 'Missing password',
		passwordLength: 'Password must be at least 6 characters',
		confirmPassword: 'Passwords do not match',
		firstName: 'Missing first name',
		lastName: 'Missing last name',
		organization: 'Missing organization',
        streetOne: 'Missing street address',
        city: 'Missing city',
        state: 'Missing state',
        zip: 'Missing zip code',
        ein: 'Missing EIN'
	};
	const [signupInProgress, setSignupInProgress] = useState(false);

	const checkForEmptyInputs = () => {
		setErrorObject(initialErrorObject);
		const newErrorObject = structuredClone(initialErrorObject);
		let foundError = false;
		for(const key of Object.keys(formData)){
            if(type === 'individual' && ['organization', 'streetOne', 'city', 'state', 'zip', 'ein'].indexOf(key) > -1){
                continue;
            }
            if(!formData[key] && key !== 'confirmPassword'){
                newErrorObject[key] = inputsMessagesErrorObject[key];
                foundError = true;
            }
		}
		setErrorObject(newErrorObject);
		return foundError;
	};

	const checkPasswordLength = () => {
		if(formData.password.length < 6){
			setErrorObject((prevState) => ({...prevState, password: inputsMessagesErrorObject.passwordLength}));
			return false;
		}
		return true;
	};

	const checkPasswordsMatch = () => {
		if(formData.password !== formData.confirmPassword){
			setErrorObject((prevState) => ({...prevState, confirmPassword: inputsMessagesErrorObject.confirmPassword}));
			return false;
		}
		return true;
	};

	const submit = async (event) => {
		event.preventDefault();
		setErrorObject(initialErrorObject);
		setSignupInProgress(true);
		if(checkForEmptyInputs()){
			setSignupInProgress(false);
			return;
		}
		if(!checkPasswordLength() || !checkPasswordsMatch()){
			setSignupInProgress(false);
			return;
		}
		const response = await signupApi(formData);
		let body;
		try{
			body = await response.json();
		}
		catch(e){}
		if(response && response.ok){
			localStorage.setItem('api-token', body.data.token);
			navigate('/dashboard');
		}
		else{
			const error = body?.error ? body.error : 'Signup failed';
			setErrorObject((prevState) => ({...prevState, apiError: error}));
		}
		setSignupInProgress(false);
	};

	useEffect(() => {
		document.title = 'Signup';
	}, []);

    useEffect(() => {
        if(type === 'individual'){
            setFormData({...formData, organization: '', streetOne: '', city: '', state: '', zip: '', ein: ''});
        }
		else if(type === 'organization'){
			setFormData({...formData, state: 'CA'});
		}
    }, [type]);

	useEffect(() => {
		const errorElements = document.getElementsByClassName('error');
		if(errorElements.length > 0){
			errorElements[0].scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}
	}, [errorObject]);

	return (
		<div className={styles.container}>
			<div className={styles.formContainer}>
				<form className={styles.form} onSubmit={submit}>
					<FormHeader className={styles.header} text="Welcome to GIVERIST!"/>
                    <div className={styles.formRadioButtonsRow}>
                        <FormRadioButton className={styles.radioButton} text="I'm an individual" selectedValue={type} value="individual" onClick={() => setType('individual')}/>
                        <FormRadioButton className={styles.radioButton} text="I'm an organization" selectedValue={type} value="organization" onClick={() => setType('organization')}/>
                    </div>
					<div className={styles.dualInputsRow}>
						<FormInput className={styles.halfLineInput} label="First name" type="text" placeholder="ie. John" value={formData.firstName}
								   update={(event) => setFormData({...formData, firstName: event.target.value})} error={errorObject.firstName} required/>
						<FormInput className={styles.halfLineInput} label="Last name" type="text" placeholder="ie. Smith" value={formData.lastName}
								   update={(event) => setFormData({...formData, lastName: event.target.value})} error={errorObject.lastName} required/>
					</div>
					<FormInput className={styles.singleLineInput} label="Email" type="email" placeholder="ie. johnsmith@gmail.com" value={formData.email}
							   update={(event) => setFormData({...formData, email: event.target.value})} error={errorObject.email} maxlength={70} required/>
					<FormInput className={styles.singleLineInput} label="Password" type="password" placeholder="****************" value={formData.password}
							   update={(event) => setFormData({...formData, password: event.target.value})} error={errorObject.password} required/>
					<FormInput className={styles.singleLineInput} label="Confirm password" type="password" placeholder="****************"
							   value={formData.confirmPassword} update={(event) => setFormData({...formData, confirmPassword: event.target.value})}
                               error={errorObject.confirmPassword} required/>
                    {
                        type === 'organization' && (
                        	<div>
                        		<FormInput className={styles.singleLineInput} label="Organization" type="text" placeholder="ie. Yale University" value={formData.organization}
										   update={(event) => setFormData({...formData, organization: event.target.value})} error={errorObject.organization} maxlength={70} required/>
                        		<FormInput className={styles.singleLineInput} label="Street address" type="text" placeholder="ie. 500 Main Street" value={formData.streetOne}
										   update={(event) => setFormData({...formData, streetOne: event.target.value})} error={errorObject.streetOne} maxlength={70} required/>
								<div className={styles.dualInputsRow}>
									<FormInput className={styles.halfLineInput} label="City" type="text" placeholder="ie. Los Angeles" value={formData.city}
											   update={(event) => setFormData({...formData, city: event.target.value})} error={errorObject.city} required/>
									<FormDropdown className={styles.halfLineInput} label="State" value={formData.state} options={stateOptions}
												  update={(event) => setFormData({...formData, state: event.target.value})} required/>
								</div>
								<div className={styles.dualInputsRow}>
									<FormInput className={styles.halfLineInput} label="Zip code" type="text" pattern="\d*" placeholder="12345"
											   value={formData.zip} update={(event) => setFormData({...formData, zip: event.target.value})} error={errorObject.zip}
											   maxlength={5} required/>
									<FormInput className={styles.halfLineInput} label="EIN number" type="text" placeholder="00-0000000" value={formData.ein}
											   update={(event) => setFormData({...formData, ein: event.target.value})} error={errorObject.ein} maxlength={10} required/>
								</div>
							</div>
                        )
                    }
					<div className={styles.consent}>
						By creating an account, I agree to the <a href="/terms" target="_blank">Terms of Use</a>
					</div>
					{
						errorObject.apiError && (
							<FormError error={errorObject.apiError}/>
						)
					}
					<FormButton className={styles.button} text="Sign up" disabled={signupInProgress}/>
				</form>
			</div>
			<div className={styles.banner}>
				<img className={styles.image} src={signup} alt="Signup"/>
				<div className={styles.bottomBar}>
					<span className={styles.bottomBarText}>
						Already have an account?
					</span>
					<ButtonLink className={styles.loginButton} href="/login" text="Login"/>
				</div>
			</div>
		</div>
	);
};

export default Signup;