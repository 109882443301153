import { useState, useEffect } from 'react';
import bank from '../../assets/images/bank.png';
import checkmarkCircleGreen from '../../assets/images/checkmark-circle-green.png';
import exclamationTriangle from '../../assets/images/exclamation-triangle.png';
import styles from './Organization.module.css';
import { getOrganizationStripeLinkApi, updateOrganizationApi } from '../../api/organizations';
import { stateOptions } from '../../data/ProfileOptions.js';
import FormInput from '../form/FormInput.jsx';
import FormDropdown from '../form/FormDropdown.jsx';
import FormButton from '../form/FormButton.jsx';
import FormButtonSuccess from '../form/FormButtonSuccess.jsx';
import FormError from '../form/FormError.jsx';
import OrganizationUrl from "./OrganizationUrl";
import ImageSection from './ImageSection.jsx';
import ButtonLink from '../ButtonLink.jsx';
import PictureModal from '../modals/PictureModal.jsx';

const Organization = (props) => {
	const [imageBase64, setImageBase64] = useState('');
	const [showPictureModal, setShowPictureModal] = useState(false);
	const [stripePayoutsEnabled, setStripePayoutsEnabled] = useState(false);
	const [stripeLink, setStripeLink] = useState('');
	const [formData, setFormData] = useState({
		name: '',
		streetOne: '',
		city: '',
		state: '',
		zip: '',
		ein: '',
		pictureUrl: ''
	});
	const initialErrorObject = {
		streetOne: false,
		city: false,
		state: false,
		zip: false,
		ein: false,
		apiError: false
	};
	const [errorObject, setErrorObject] = useState(initialErrorObject);
	const inputsMessagesErrorObject = {
		streetOne: 'Missing street address',
		city: 'Missing city',
		state: 'Missing state',
		zip: 'Missing zip code',
		ein: 'Missing EIN'
	};
	const [saveInProgress, setSaveInProgress] = useState(false);
	const [saveSuccessful, setSaveSuccessful] = useState(false);

	const getOrganizationStripeLink = async () => {
		const response = await getOrganizationStripeLinkApi();
		const body = await response.json();
		if(response && response.ok){
			setStripePayoutsEnabled(body.data.payouts_enabled);
			setStripeLink(body.data.link);
		}
	};

	const checkForEmptyInputs = () => {
		setErrorObject(initialErrorObject);
		const newErrorObject = structuredClone(initialErrorObject);
		let foundError = false;
		for(const key of Object.keys(formData)){
			if(!formData[key] && key !== 'pictureUrl'){
				newErrorObject[key] = inputsMessagesErrorObject[key];
				foundError = true;
			}
		}
		setErrorObject(newErrorObject);
		return foundError;
	};

	const submit = async (event) => {
		event.preventDefault();
		setErrorObject(initialErrorObject);
		setSaveInProgress(true);
		if(checkForEmptyInputs()){
			setSaveInProgress(false);
			return;
		}
		const response = await updateOrganizationApi(formData);
		let body;
		try{
			body = await response.json();
		}
		catch(e){}
		if(response && response.ok){
			props.getUser();
			setSaveSuccessful(true);
		}
		else{
			const error = body?.error ? body.error : 'Update failed';
			setErrorObject((prevState) => ({...prevState, apiError: error}));
		}
		setSaveInProgress(false);
	};

	useEffect(() => {
		getOrganizationStripeLink();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if(props.user){
			setFormData({
				name: props.user?.organization_name ? props.user.organization_name : '',
				streetOne: props.user?.street_one ? props.user.street_one : '',
				city: props.user?.city ? props.user.city : '',
				state: props.user?.state ? props.user.state : '',
				zip: props.user?.zip ? props.user.zip : '',
				ein: props.user?.ein ? props.user.ein : '',
				pictureUrl: props.user?.picture_url ? props.user.picture_url: ''
			});
		}
	}, [props.user]);

	useEffect(() => {
		const errorElements = document.getElementsByClassName('error');
		if(errorElements.length > 0){
			errorElements[0].scrollIntoView({
				block: 'start',
				behavior: 'smooth',
			});
		}
	}, [errorObject]);

	useEffect(() => {
		if(stripeLink){
			console.log(window.location.hash);
			if(window.location.hash === '#bank-info'){
				console.log('hi');
				document.getElementById('bank-info').scrollIntoView();
			}
		}
	}, [stripeLink]);

	return (
        <div className={props.className}>
            <ImageSection formData={formData} type={'organization'} setImageBase64={setImageBase64} setShowPictureModal={setShowPictureModal}/>
            <OrganizationUrl className={styles.organizationUrl} organizationId={props.user?.organization_id} organizationSlug={props.user?.organization_slug}/>
            <form onSubmit={submit}>
                <FormInput className={styles.singleLineInput} label="Organization name" type="text" value={formData.name} disabled={true}/>
				<FormInput className={styles.singleLineInput} label="Street address" type="text" value={formData.streetOne}
                           update={(event) => setFormData({...formData, streetOne: event.target.value})} error={errorObject.streetOne} maxlength={70} required/>
                <div className={styles.dualInputsRow}>
                    <FormInput className={styles.halfLineInput} label="City" type="text" value={formData.city} update={(event) => setFormData({...formData, city: event.target.value})}
							   error={errorObject.city} required/>
                    <FormDropdown className={styles.halfLineInput} label="State" value={formData.state} options={stateOptions}
                                  update={(event) => setFormData({...formData, state: event.target.value})} error={errorObject.state} required/>
                </div>
				<div className={styles.dualInputsRow}>
					<FormInput className={styles.halfLineInput} label="Zip code" type="text" pattern="\d*" value={formData.zip}
							   update={(event) => setFormData({...formData, zip: event.target.value})} error={errorObject.zip} maxlength={5} required/>
                	<FormInput className={styles.halfLineInput} label="EIN number" type="text" value={formData.ein} update={(event) => setFormData({...formData, ein: event.target.value})}
							   error={errorObject.ein} maxlength={10} required/>
				</div>
                {
                    stripeLink && (
                        <div id="bank-info" className={styles.bankInfoRow}>
							<span className={styles.bankInfoLabel}>
								<img className={styles.bankIcon} src={bank} alt="Bank"/>
								Payout Info
								{
									stripePayoutsEnabled && (
										<img className={styles.checkmark} src={checkmarkCircleGreen} alt="Checkmark" title="Payout setup complete"/>
									)
								}
								{
									!stripePayoutsEnabled && (
										<img className={styles.exclamation} src={exclamationTriangle} alt="Exclamation" title="Missing info for payouts"/>
									)
								}
							</span>
                            <ButtonLink className={styles.bankInfoButton} href={stripeLink} target={stripePayoutsEnabled ? '_blank' : '_self'}
                                        text={stripePayoutsEnabled ? 'Update info' : 'Complete setup'}/>
                        </div>
                    )
                }
                {
                    errorObject.apiError && (
                        <FormError error={errorObject.apiError}/>
                    )
                }
                {
                    !saveSuccessful && (
                        <FormButton className={styles.button} text="Save changes" disabled={saveInProgress}/>
                    )
                }
                {
                    saveSuccessful && (
                        <FormButtonSuccess className={styles.button} text="Updates saved"/>
                    )
                }
                {
                    showPictureModal && (
                        <PictureModal imageBase64={imageBase64} user={props.user} type="organization" update={(newPictureUrl) => setFormData({...formData, pictureUrl: newPictureUrl})}
                                      close={() => setShowPictureModal(false)}/>
                    )
                }
            </form>
        </div>
    );
};

export default Organization;