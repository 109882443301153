const createFundraiserApi = async (formData) => {
	const token = localStorage.getItem('api-token');
	const editedFormData = {
		name: formData.name,
		description: formData.description,
        organization_id: formData.organizationId,
		start_at: formData.startAt,
		end_at: formData.endAt,
		goal_amount: formData.goalAmount,
		pictures_urls: formData.images
	};
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			token: token
		},
		body: JSON.stringify(editedFormData)
	};
	return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/fundraiser', options);
};

const createFundraiserUpdateApi = async (fundraiserId, formData) => {
    const token = localStorage.getItem('api-token');
    const editedFormData = {
        title: formData.title,
        text: formData.text,
        pictures_urls: formData.images
    };
    const options = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            token: token
        },
        body: JSON.stringify(editedFormData)
    };
    return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/fundraiser/' + fundraiserId + '/update', options);
};

const getAllFundraisresApi = async () => {
    const options = {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    };
    return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/fundraisers/all', options);
};

const getCurrentUserFundraisersApi = async () => {
	const token = localStorage.getItem('api-token');
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			token: token
		}
	};
	return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/fundraisers', options);
};

const getFundraiserApi = async (fundraiserId) => {
	const token = localStorage.getItem('api-token');
	const options = {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			token: token
		}
	};
	return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/fundraiser/' + fundraiserId, options);
};

const submitDonationApi = async (fundraiserId, formData, stripeConfirmationToken, lastFourDigitsOfCard) => {
	const editedFormData = {
		amount: formData.amount,
		email: formData.email,
		name: formData.displayName,
		comment: formData.comment,
		stripe_confirmation_token: stripeConfirmationToken,
		last_four_digits_of_card: lastFourDigitsOfCard
	};
	const options = {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(editedFormData)
	};
	return await fetch(process.env.REACT_APP_BACK_END_DOMAIN + '/fundraiser/' + fundraiserId + '/donate', options);
};

export { createFundraiserApi, createFundraiserUpdateApi, getAllFundraisresApi, getCurrentUserFundraisersApi, getFundraiserApi, submitDonationApi };