import styles from './Terms.module.css';

const Terms = () => {
	return (
		<div className={styles.container}>
			yoyoyo
		</div>
	)
};

export default Terms;